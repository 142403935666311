<template>
  <div v-if="chatData">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        v-if="$ability.can('read', this.$route.meta.resource)"
        class="chat-widget"
        no-body
      >
        <b-card-header>
          <div class="d-flex align-items-center">
            <b-avatar
              size="34"
              :src="chatData.profilePartner.image"
              class="mr-50 badge-minimal"
              badge
              badge-variant="success"
            />
            <h5 class="mb-0">
              {{ chatData.profilePartner.firstName }}  {{ chatData.profilePartner.lastName }}
            </h5>
          </div>

          <!-- <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
              class="dropdown-notification mr-25"
              menu-class="dropdown-menu-media"
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                />
              </template>
              <b-dropdown-item
                link-class="d-flex align-items-center"
                @click="review"
              >
                <feather-icon
                  size="16"
                  icon="StarIcon"
                  class="mr-50"
                />
                <span>{{ $t("Change To Review") }}</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav> -->

        </b-card-header>

        <section class="chat-app-window">
          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
            style="height: 60vh;"
          >
            <chat-log
              :chat-data="chatData"
              :profile-user-avatar="menuService == 'franchise' ? getFranchiseData.logo : menuService == 'company' ? getCompanyData.logo : menuService == 'hero' ? getApplicationData.logo : require('@/assets/images/avatars/10.png')"
            />
          </vue-perfect-scrollbar>

                    <!-- Message Input -->
                    <b-form
            class="chat-app-form"
            @submit.prevent="sendMessage"
          >
            <b-input-group class="input-group-merge form-send-message mr-1">
              <!-- <b-img
              v-if="image"
              ref="previewEl"
              class="mr-2"
              rounded
              :src="image"
              height="40"
            /> -->
              <b-form-input
                v-model="chatInputMessage"
                :placeholder="$t('Enter your message')"
                @input="sendTypingWS($event)"
              />
            </b-input-group>
            <!-- <b-form-file
              ref="refInputEl"
              accept=".webp, .jpeg, .jpg, .png, .gif"
              :hidden="true"
              plain
              @change="inputImageRenderer"
            />
            <b-button
              class="mr-2"
              variant="success"
              type="submit"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t("Attach file") }}
            </b-button> -->
            <b-button
              variant="primary"
              type="submit"
            >
              {{ $t("Send") }}
            </b-button>
          </b-form>

          <!-- Message Input -->
        </section>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import store from '@/store'

import {
  BImg,
  BOverlay,
  BCard,
  BCardHeader,
  BAvatar,
  BForm,
  BFormInput,
  BFormFile,
  BInputGroup,
  BButton,
  BNavItemDropdown,
  BDropdownItem,
  BNavbarNav,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import debounce from 'debounce'
import Ripple from 'vue-ripple-directive'
import storeModule from '@/store/services/hero/chat'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ChatLog from './components/ChatLog.vue'

// import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

const STORE_MODULE_NAME = 'chat'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    BOverlay,
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    BInputGroup,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BNavItemDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BNavbarNav,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    VuePerfectScrollbar,
    ChatLog,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      dataId: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      chatInputMessage: '',
      image: '',
    }
  },

  computed: {
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME]
    },
    menuService() {
      return store.state.appConfig.menuService
    },
    chatData() {
      return store.state[STORE_MODULE_NAME].chatData != null ? store.state[STORE_MODULE_NAME].chatData : null
    },
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    authType() {
      if (this.getUserData.userType.toUpperCase() === this.menuService.toUpperCase()) {
        return this.$t(this.menuService.toUpperCase())
      }
      return `${this.$t(this.menuService.toUpperCase())} (${this.$t(this.getUserData.userType.toUpperCase())})`
    },
    getUserData() {
      return localStorage.getItem('userData') != null
        ? JSON.parse(localStorage.getItem('userData'))
        : null
    },
    getFranchiseData() {
      return localStorage.getItem('franchiseData') != null
        ? JSON.parse(localStorage.getItem('franchiseData'))
        : null
    },
    getCompanyData() {
      return localStorage.getItem('companyData') != null
        ? JSON.parse(localStorage.getItem('companyData'))
        : null
    },
    getApplicationData() {
      return localStorage.getItem('applicationData') != null
        ? JSON.parse(localStorage.getItem('applicationData'))
        : null
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // WebSocket Disconnect
      store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)

      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

      const userData = JSON.parse(localStorage.getItem('userData'))
      const applicationId = localStorage.getItem('applicationId')
      const Id = to.params.id // ContactID
      const { userType } = userData
      this.show = true
      this.dataId = Id
      // WebSocket Connect
      const pathname = `/Contact?type=${userType}&applicationId=${applicationId}&userId=${Id}`
      const params = {
        pathname,
        page: STORE_MODULE_NAME,
      }
      store.dispatch('websockets/connect', params)
      const obj = {
        roomChatId: this.dataId,
      }
      this.$forceUpdate()
      store
        .dispatch(`${STORE_MODULE_NAME}/getLogChat`, obj)
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          console.log('fetch Success : ', result)
          this.$nextTick(() => {
            this.psToBottom()
            this.show = false
          })
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.show = false

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)

    const userData = JSON.parse(localStorage.getItem('userData'))
    const applicationId = localStorage.getItem('applicationId')
    const Id = this.$route.params.id // ContactID
    const { userType } = userData
    this.show = true
    this.dataId = Id
    // WebSocket Connect
    const pathname = `/Support?type=${userType}&applicationId=${applicationId}&id=${Id}`
    const params = {
      pathname,
      page: STORE_MODULE_NAME,
    }
    store.dispatch('websockets/connect', params)
    const obj = {
      roomChatId: this.dataId,
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/getLogChat`, obj)
      // eslint-disable-next-line no-unused-vars
      .then(result => {
        console.log('fetch Success : ', result)
        this.$nextTick(() => {
          this.psToBottom()
          this.show = false
        })
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        this.show = false

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.sendTypingWS = debounce(this.sendTypingWS, 500)
  },
  destroyed() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)

    // WebSocket Disconnect
    store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  updated() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)
        console.log('SocketMessage')
        console.log(SocketMessage)
        if (SocketMessage.message !== 'WS_CONNECTED') {
          if (SocketMessage.data.method !== 'VIEW' && SocketMessage.data.method !== 'UPDATE') {
            store.commit(`${STORE_MODULE_NAME}/SEND_MESSAGE`, SocketMessage.data.send.data)
            this.$nextTick(() => {
              this.psToBottom()
            })
          }
        }
      }
    }
  },
  methods: {
    inputImageRenderer(input) {
      this.show = true

      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 300000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          const obj = {
            roomChatId: this.dataId,
            image: this.image,
            userId: this.chatData.userInfo.userId,
            type: 1,
          }
          store
            .dispatch(`${STORE_MODULE_NAME}/uploadImage`, obj)
            .then(result => {
              console.log('fetch Success : ', result)
              this.chatInputMessage = ''

              // Update scroll position
              // Scroll to bottom
              this.$nextTick(() => {
                this.psToBottom()
                this.show = false
              })
              // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
            })
            .catch(error => {
              this.show = false
              console.log('fetchUsers Error : ', error)
              // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
            })
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      } else {
        this.show = false
      }
    },
    // review() {
    //   this.show = true

    //   const obj = {
    //     roomChatId: this.dataId,
    //   }
    //   store
    //     .dispatch(`${STORE_MODULE_NAME}/setInReview`, obj)
    //     .then(result => {
    //       this.show = false

    //       console.log('fetch Success : ', result)
    //       // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
    //     })
    //     .catch(error => {
    //       this.show = false
    //       console.log('fetchUsers Error : ', error)
    //       // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
    //     })
    // },
    sendTypingWS(value) {
      console.log(value)
      if (value.trim() !== '') {
        const dataSend = {
          data: null, // ข้อมูลที่ส่ง
          page: 'SUPPORT_HERO', // ชื่อเมนู
        }
        const webSocketData = {
          send: dataSend, // ข้อมูลที่จะส่ง ห้ามเป็น Array [Map]
          // ส่งไปหา users(ผู้ใช้ทั้งหมดที่ออนไลน)
          // users(ผู้ใช้งานทั้งหมด)
          // root(ผู้ดูแลระบบทั้งหมด)
          // agents(ตัวแทนทั้งหมด)
          // members(คนขับทั้งหมด)
          // driver(คนขับทั้งหมด)
          // web(เว็บทั้งหมด)
          // mobile(มือถือทั้งหมด)
          // ถ้าจะส่งหา USER แยก จะใช้ USER_{ObjectID ของผู้ใช้ที่จะส่ง} [refer_users] [USER_61554f40a0f9d6f42808655e]
          // ถ้าจะส่งหา Call a car แยก จะใช้ CALL_{ObjectID ของการเรียกรถ} [refer_call_a_car] [CALL_61554f40a0f9d6f42808655e]
          // ถ้าจะส่งหา Contact Us แยก จะใช้ CONTACT_{ ObjectID ของการสนทนา } [refer_contact_us] [CONTACT_61554f40a0f9d6f42808655e]
          author: [`SUPPORT_${this.dataId}`], // Example author: ['root','agents','USER_61554f40a0f9d6f42808655e'],
          method: 'VIEW', // ['ADD','DELETE','UPDATE','VIEW']
          sendMethod: 'WAIT_DATA', // WAIT_DATA รอข้อมูล เช่น กำลังตอบกลับ แต่ไม่ได้ส่งอะไรมาใน send['data'] ด้วย , SEND_DATA ส่งข้อมูล เช่น เก็บข้อมูล ส่งข้อมูลมาใน send['data'] ด้วย

          // ผู้ส่งไป
          type: 'company', //  Web :['root','agents'] , Moblie :['member','driver'] *เอาไว้กันลืม [String]
          pathname: 'Web', // ['Web','Mobile'] *เอาไว้กันลืม [String]
        }
        const obj = {
          data: webSocketData,
          page: STORE_MODULE_NAME,
        }
        store.dispatch('websockets/sendMessage', obj)
      }
    },
    sendMessage() {
      if (this.chatInputMessage.length > 0) {
        this.show = true

        const obj = {
          roomChatId: this.dataId,
          message: this.chatInputMessage,
          type: 'text',
        }
        store
          .dispatch(`${STORE_MODULE_NAME}/sendMessage`, obj)
          .then(result => {
            console.log('fetch Success : ', result)
            this.chatInputMessage = ''

            // Update scroll position
            // Scroll to bottom
            this.$nextTick(() => {
              this.psToBottom()
              this.show = false
            })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          })
          .catch(error => {
            this.show = false
            console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
          })
      }
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
